const isReactNative = typeof navigator > "u" ? !1 : navigator.product === "ReactNative",
  defaultOptions = {
    timeout: isReactNative ? 6e4 : 12e4
  },
  processOptions = function (opts) {
    const options = {
      ...defaultOptions,
      ...(typeof opts == "string" ? {
        url: opts
      } : opts)
    };
    if (options.timeout = normalizeTimeout(options.timeout), options.query) {
      const {
        url,
        searchParams
      } = splitUrl(options.url);
      for (const [key, value] of Object.entries(options.query)) {
        if (value !== void 0) if (Array.isArray(value)) for (const v of value) searchParams.append(key, v);else searchParams.append(key, value);
        const search = searchParams.toString();
        search && (options.url = `${url}?${search}`);
      }
    }
    return options.method = options.body && !options.method ? "POST" : (options.method || "GET").toUpperCase(), options;
  };
function splitUrl(url) {
  const qIndex = url.indexOf("?");
  if (qIndex === -1) return {
    url,
    searchParams: new URLSearchParams()
  };
  const base = url.slice(0, qIndex),
    qs = url.slice(qIndex + 1);
  if (!isReactNative) return {
    url: base,
    searchParams: new URLSearchParams(qs)
  };
  if (typeof decodeURIComponent != "function") throw new Error("Broken `URLSearchParams` implementation, and `decodeURIComponent` is not defined");
  const params = new URLSearchParams();
  for (const pair of qs.split("&")) {
    const [key, value] = pair.split("=");
    key && params.append(decodeQueryParam(key), decodeQueryParam(value || ""));
  }
  return {
    url: base,
    searchParams: params
  };
}
function decodeQueryParam(value) {
  return decodeURIComponent(value.replace(/\+/g, " "));
}
function normalizeTimeout(time) {
  if (time === !1 || time === 0) return !1;
  if (time.connect || time.socket) return time;
  const delay = Number(time);
  return isNaN(delay) ? normalizeTimeout(defaultOptions.timeout) : {
    connect: delay,
    socket: delay
  };
}
const validUrl = /^https?:\/\//i,
  validateOptions = function (options) {
    if (!validUrl.test(options.url)) throw new Error(`"${options.url}" is not a valid URL`);
  };
function getDefaultExportFromCjs(x) {
  return x && x.__esModule && Object.prototype.hasOwnProperty.call(x, "default") ? x.default : x;
}
export { getDefaultExportFromCjs, processOptions, validateOptions };
