import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';

import { createClient, QueryParams, QueryWithoutParams, SanityClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';
import type { ImageUrlBuilder } from '@sanity/image-url/lib/types/builder';
import type { SanityImageSource } from '@sanity/image-url/lib/types/types';
import { map, Observable, tap } from 'rxjs';

import {
    ISanityArticle,
    ISanityBanner,
    ISanityContributor,
    ISanityInfoPage,
    ISanityLandingPage,
    ISanityReview,
    TSanityContributorTeam,
    TSanityInfoPageType
} from '@vb/shared/interfaces';

import { ARTICLE, CONTRIBUTOR, CONTRIBUTOR_FULL, INFO_PAGE, REVIEW } from './sanity-queries.config';
import { SanityDataAccessOptions } from './sanity.config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Zone: any;

export interface ISanityFetchOptions {
    page?: number;
    pageSize?: number;
    excludeId?: string | null;
}

export interface ISanityArticleFetchOptions extends ISanityFetchOptions {
    category?: string;
}

export interface ISanityContributorFetchOptions extends ISanityFetchOptions {
    team: TSanityContributorTeam;
}

export interface ISanityInfoPageFetchOptions extends ISanityFetchOptions {
    type?: TSanityInfoPageType;
}

export const DEFAULT_SANITY_FETCH_OPTIONS: Required<ISanityFetchOptions> = {
    page: 1,
    pageSize: 12,
    excludeId: null
};

@Injectable({
    providedIn: 'root'
})
export class SanityAccessService {
    private client: SanityClient;

    private imageUrlBuilder: ImageUrlBuilder;

    constructor(
        @Inject(PLATFORM_ID) private platformId: object,
        @Optional() options?: SanityDataAccessOptions
    ) {
        if (!options) {
            throw new Error(
                'SanityDataAccessOptions has not been set, cannot create Sanity client'
            );
        }

        this.client = createClient({
            projectId: options.projectId,
            dataset: options.dataset,
            useCdn: options.useCdn,
            apiVersion: options.apiVersion,
            token: options.token,
            perspective: 'published'
        });

        this.imageUrlBuilder = imageUrlBuilder(this.client);
    }

    fetch<R, Q extends QueryWithoutParams | QueryParams = QueryParams>(
        query: string,
        params: Q extends QueryWithoutParams ? QueryWithoutParams : Q
    ): Observable<R> {
        const microTask =
            isPlatformServer(this.platformId) ?
                Zone.current.scheduleMacroTask(
                    `WAIT_FOR_SANITY-${Math.random()}`,
                    () => null,
                    {},
                    () => null
                )
            :   undefined;

        return this.client.observable.fetch<R, Q>(query, params).pipe(
            tap({
                next: microTask?.invoke,
                error: microTask?.invoke,
                complete: microTask?.invoke
            })
        );
    }

    getImageUrlBuilder(source: SanityImageSource): ImageUrlBuilder {
        return this.imageUrlBuilder.image(source);
    }

    getProductBanner(): Observable<ISanityBanner> {
        return this.fetch(
            `
            *[_type == "banner"] | order(_updatedAt desc) [0]
            {
                "author": author->{
                    ${CONTRIBUTOR}
                },
                description,
                "product":product->productData
            }
        `,
            {}
        );
    }

    getInfoPages(options: ISanityInfoPageFetchOptions) {
        const fetchOptions = { ...DEFAULT_SANITY_FETCH_OPTIONS, ...options };

        const startIndex = (fetchOptions.page - 1) * fetchOptions.pageSize;
        const endIndex = startIndex + fetchOptions.pageSize;

        const q = [`_type == "infoPage"`];

        if (options.type) {
            q.push('pageType == $type');
        }

        if (options.excludeId) {
            q.push('slug.current != $excludeId');
        }

        const query = `[${q.join(' && ')}]`;

        return this.fetch<ISanityInfoPage[]>(
            `*${query} [${startIndex}...${endIndex}]
            {
                ${INFO_PAGE}
            }`,
            {
                type: options.type ?? '',
                excludeId: options.excludeId ?? ''
            }
        );
    }

    getInfoPageBySlug(slug: string): Observable<ISanityInfoPage> {
        return this.fetch(
            `*[_type == "infoPage" && slug.current == $slug][0]
                {
                    ${INFO_PAGE}
                }`,
            { slug }
        );
    }

    getContributors(options: ISanityContributorFetchOptions): Observable<ISanityContributor[]> {
        const fetchOptions = { ...DEFAULT_SANITY_FETCH_OPTIONS, ...options };

        const startIndex = (fetchOptions.page - 1) * fetchOptions.pageSize;
        const endIndex = startIndex + fetchOptions.pageSize;

        const q = [`_type == "contributor"`];

        q.push('active == true');

        if (options.team) {
            q.push('team == $team');
        }

        const query = `[${q.join(' && ')}]`;

        return this.fetch<ISanityContributor[]>(
            `*${query} [${startIndex}...${endIndex}]
            {
                ${CONTRIBUTOR_FULL}
            }`,
            {
                team: options.team ?? ''
            }
        );
    }

    getContributorBySlug(slug: string) {
        return this.fetch<ISanityContributor>(
            `*[_type == "contributor" && slug.current == $slug][0]
            {
                ${CONTRIBUTOR_FULL}
            }`,
            { slug }
        ).pipe(
            map((res) => {
                if (!res) {
                    throw new Error('Contributor was not found');
                }

                return res;
            })
        );
    }

    getLandingPageData(): Observable<ISanityLandingPage> {
        return this.fetch(
            `*[_id == "b9bc8311-7a5c-4c5a-977c-1e50d9f321fa"][0]{
                "heroProducts": heroProducts[]->
                productData
                ,
                heroArticle->{
                    ${ARTICLE}
                },
                "inspiration": articles[]->{
                    ${ARTICLE}
                },
                "popularProducts": popularProducts[]->productData
              }`,
            {}
        );
    }

    //Fetch for inspiriation articles -- ta bort async/await när den är satt som observable
    getArticles(options: Partial<ISanityArticleFetchOptions> = {}): Observable<ISanityArticle[]> {
        const fetchOptions = { ...DEFAULT_SANITY_FETCH_OPTIONS, ...options };

        const startIndex = (fetchOptions.page - 1) * fetchOptions.pageSize;
        const endIndex = startIndex + fetchOptions.pageSize;

        const q = [`_type == "article"`];

        if (options.category) {
            q.push('category->slug.current == $categorySlug');
        }

        if (options.excludeId) {
            q.push('_id != $excludeId');
        }

        const query = `[${q.join(' && ')}]`;

        return this.fetch<ISanityArticle[]>(
            `*${query} | order(publishedAt desc) [${startIndex}...${endIndex}]
            {
                ${ARTICLE}
            }`,
            {
                categorySlug: options.category ?? '',
                excludeId: options.excludeId ?? ''
            }
        );
    }

    //Fetch for single article  -- ta bort async/await när den är satt som observable
    getArticleBySlug(slug: string) {
        return this.fetch<ISanityArticle>(
            `*[_type == "article" && slug.current == $slug][0]
            {
                ${ARTICLE}
            }`,
            { slug }
        ).pipe(
            map((res) => {
                if (!res) {
                    throw new Error('Article was not found');
                }

                return res;
            })
        );
    }

    //Fetch for single article  -- ta bort async/await när den är satt som observable
    getReviewsByProductId(productId: string) {
        return this.fetch<ISanityReview[]>(
            `*[_type == "review" && product->productId == $productId]
            | order(publishedAt desc) | order(grade desc)
            {
                ${REVIEW}
            }`,
            { productId }
        );
    }

    getReviewsByAuthor(slug: string, options: ISanityFetchOptions) {
        const fetchOptions = { ...DEFAULT_SANITY_FETCH_OPTIONS, ...options };

        const startIndex = (fetchOptions.page - 1) * fetchOptions.pageSize;
        const endIndex = startIndex + fetchOptions.pageSize;

        return this.fetch<ISanityReview[]>(
            `*[_type == "review" && author->slug.current == $slug ]
            | order(publishedAt desc)
            [${startIndex}...${endIndex}]
            {
                ${REVIEW}
            }`,
            {
                slug
            }
        );
    }
}
