import { processOptions, validateOptions, getDefaultExportFromCjs } from "./_chunks-es/_commonjsHelpers.js";
const middlewareReducer = middleware => function (hook, defaultValue, ...args) {
  const bailEarly = hook === "onError";
  let value = defaultValue;
  for (let i = 0; i < middleware[hook].length; i++) {
    const handler = middleware[hook][i];
    if (value = handler(value, ...args), bailEarly && !value) break;
  }
  return value;
};
function createPubSub() {
  const subscribers = /* @__PURE__ */Object.create(null);
  let nextId = 0;
  function subscribe(subscriber) {
    const id = nextId++;
    return subscribers[id] = subscriber, function () {
      delete subscribers[id];
    };
  }
  function publish(event) {
    for (const id in subscribers) subscribers[id](event);
  }
  return {
    publish,
    subscribe
  };
}
const channelNames = ["request", "response", "progress", "error", "abort"],
  middlehooks = ["processOptions", "validateOptions", "interceptRequest", "finalizeOptions", "onRequest", "onResponse", "onError", "onReturn", "onHeaders"];
function createRequester(initMiddleware, httpRequest) {
  const loadedMiddleware = [],
    middleware = middlehooks.reduce((ware, name) => (ware[name] = ware[name] || [], ware), {
      processOptions: [processOptions],
      validateOptions: [validateOptions]
    });
  function request(opts) {
    const onResponse = (reqErr, res, ctx) => {
        let error = reqErr,
          response = res;
        if (!error) try {
          response = applyMiddleware("onResponse", res, ctx);
        } catch (err) {
          response = null, error = err;
        }
        error = error && applyMiddleware("onError", error, ctx), error ? channels.error.publish(error) : response && channels.response.publish(response);
      },
      channels = channelNames.reduce((target, name) => (target[name] = createPubSub(), target), {}),
      applyMiddleware = middlewareReducer(middleware),
      options = applyMiddleware("processOptions", opts);
    applyMiddleware("validateOptions", options);
    const context = {
      options,
      channels,
      applyMiddleware
    };
    let ongoingRequest;
    const unsubscribe = channels.request.subscribe(ctx => {
      ongoingRequest = httpRequest(ctx, (err, res) => onResponse(err, res, ctx));
    });
    channels.abort.subscribe(() => {
      unsubscribe(), ongoingRequest && ongoingRequest.abort();
    });
    const returnValue = applyMiddleware("onReturn", channels, context);
    return returnValue === channels && channels.request.publish(context), returnValue;
  }
  return request.use = function (newMiddleware) {
    if (!newMiddleware) throw new Error("Tried to add middleware that resolved to falsey value");
    if (typeof newMiddleware == "function") throw new Error("Tried to add middleware that was a function. It probably expects you to pass options to it.");
    if (newMiddleware.onReturn && middleware.onReturn.length > 0) throw new Error("Tried to add new middleware with `onReturn` handler, but another handler has already been registered for this event");
    return middlehooks.forEach(key => {
      newMiddleware[key] && middleware[key].push(newMiddleware[key]);
    }), loadedMiddleware.push(newMiddleware), request;
  }, request.clone = () => createRequester(loadedMiddleware, httpRequest), initMiddleware.forEach(request.use), request;
}
var trim = function (string) {
    return string.replace(/^\s+|\s+$/g, "");
  },
  isArray = function (arg) {
    return Object.prototype.toString.call(arg) === "[object Array]";
  },
  parseHeaders = function (headers) {
    if (!headers) return {};
    for (var result = {}, headersArr = trim(headers).split(`
`), i = 0; i < headersArr.length; i++) {
      var row = headersArr[i],
        index = row.indexOf(":"),
        key = trim(row.slice(0, index)).toLowerCase(),
        value = trim(row.slice(index + 1));
      typeof result[key] > "u" ? result[key] = value : isArray(result[key]) ? result[key].push(value) : result[key] = [result[key], value];
    }
    return result;
  },
  parseHeaders$1 = /* @__PURE__ */getDefaultExportFromCjs(parseHeaders),
  __defProp = Object.defineProperty,
  __typeError = msg => {
    throw TypeError(msg);
  },
  __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value
  }) : obj[key] = value,
  __publicField = (obj, key, value) => __defNormalProp(obj, typeof key != "symbol" ? key + "" : key, value),
  __accessCheck = (obj, member, msg) => member.has(obj) || __typeError("Cannot " + msg),
  __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd = (obj, member, value) => member.has(obj) ? __typeError("Cannot add the same private member more than once") : member instanceof WeakSet ? member.add(obj) : member.set(obj, value),
  __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), member.set(obj, value), value),
  _method,
  _url,
  _resHeaders,
  _headers,
  _controller,
  _init,
  _useAbortSignal;
class FetchXhr {
  constructor() {
    __publicField(this, "onabort"), __publicField(this, "onerror"), __publicField(this, "onreadystatechange"), __publicField(this, "ontimeout"), __publicField(this, "readyState", 0), __publicField(this, "response"), __publicField(this, "responseText", ""), __publicField(this, "responseType", ""), __publicField(this, "status"), __publicField(this, "statusText"), __publicField(this, "withCredentials"), __privateAdd(this, _method), __privateAdd(this, _url), __privateAdd(this, _resHeaders), __privateAdd(this, _headers, {}), __privateAdd(this, _controller), __privateAdd(this, _init, {}), __privateAdd(this, _useAbortSignal);
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars -- _async is only declared for typings compatibility
  open(method, url, _async) {
    var _a;
    __privateSet(this, _method, method), __privateSet(this, _url, url), __privateSet(this, _resHeaders, ""), this.readyState = 1, (_a = this.onreadystatechange) == null || _a.call(this), __privateSet(this, _controller, void 0);
  }
  abort() {
    __privateGet(this, _controller) && __privateGet(this, _controller).abort();
  }
  getAllResponseHeaders() {
    return __privateGet(this, _resHeaders);
  }
  setRequestHeader(name, value) {
    __privateGet(this, _headers)[name] = value;
  }
  // Allow setting extra fetch init options, needed for runtimes such as Vercel Edge to set `cache` and other options in React Server Components
  setInit(init, useAbortSignal = !0) {
    __privateSet(this, _init, init), __privateSet(this, _useAbortSignal, useAbortSignal);
  }
  send(body) {
    const textBody = this.responseType !== "arraybuffer",
      options = {
        ...__privateGet(this, _init),
        method: __privateGet(this, _method),
        headers: __privateGet(this, _headers),
        body
      };
    typeof AbortController == "function" && __privateGet(this, _useAbortSignal) && (__privateSet(this, _controller, new AbortController()), typeof EventTarget < "u" && __privateGet(this, _controller).signal instanceof EventTarget && (options.signal = __privateGet(this, _controller).signal)), typeof document < "u" && (options.credentials = this.withCredentials ? "include" : "omit"), fetch(__privateGet(this, _url), options).then(res => {
      var _a;
      return res.headers.forEach((value, key) => {
        __privateSet(this, _resHeaders, __privateGet(this, _resHeaders) + `${key}: ${value}\r
`);
      }), this.status = res.status, this.statusText = res.statusText, this.readyState = 3, (_a = this.onreadystatechange) == null || _a.call(this), textBody ? res.text() : res.arrayBuffer();
    }).then(resBody => {
      var _a;
      typeof resBody == "string" ? this.responseText = resBody : this.response = resBody, this.readyState = 4, (_a = this.onreadystatechange) == null || _a.call(this);
    }).catch(err => {
      var _a, _b;
      if (err.name === "AbortError") {
        (_a = this.onabort) == null || _a.call(this);
        return;
      }
      (_b = this.onerror) == null || _b.call(this, err);
    });
  }
}
_method = /* @__PURE__ */new WeakMap(), _url = /* @__PURE__ */new WeakMap(), _resHeaders = /* @__PURE__ */new WeakMap(), _headers = /* @__PURE__ */new WeakMap(), _controller = /* @__PURE__ */new WeakMap(), _init = /* @__PURE__ */new WeakMap(), _useAbortSignal = /* @__PURE__ */new WeakMap();
const adapter = typeof XMLHttpRequest == "function" ? "xhr" : "fetch",
  XmlHttpRequest = adapter === "xhr" ? XMLHttpRequest : FetchXhr,
  httpRequester = (context, callback) => {
    var _a;
    const opts = context.options,
      options = context.applyMiddleware("finalizeOptions", opts),
      timers = {},
      injectedResponse = context.applyMiddleware("interceptRequest", void 0, {
        adapter,
        context
      });
    if (injectedResponse) {
      const cbTimer = setTimeout(callback, 0, null, injectedResponse);
      return {
        abort: () => clearTimeout(cbTimer)
      };
    }
    let xhr = new XmlHttpRequest();
    xhr instanceof FetchXhr && typeof options.fetch == "object" && xhr.setInit(options.fetch, (_a = options.useAbortSignal) != null ? _a : !0);
    const headers = options.headers,
      delays = options.timeout;
    let aborted = !1,
      loaded = !1,
      timedOut = !1;
    if (xhr.onerror = event => {
      xhr instanceof FetchXhr ? onError(event instanceof Error ? event : new Error(`Request error while attempting to reach is ${options.url}`, {
        cause: event
      })) : onError(new Error(`Request error while attempting to reach is ${options.url}${event.lengthComputable ? `(${event.loaded} of ${event.total} bytes transferred)` : ""}`));
    }, xhr.ontimeout = event => {
      onError(new Error(`Request timeout while attempting to reach ${options.url}${event.lengthComputable ? `(${event.loaded} of ${event.total} bytes transferred)` : ""}`));
    }, xhr.onabort = () => {
      stopTimers(!0), aborted = !0;
    }, xhr.onreadystatechange = () => {
      resetTimers(), !(aborted || xhr.readyState !== 4) && xhr.status !== 0 && onLoad();
    }, xhr.open(options.method, options.url, !0
    // Always async
    ), xhr.withCredentials = !!options.withCredentials, headers && xhr.setRequestHeader) for (const key in headers) headers.hasOwnProperty(key) && xhr.setRequestHeader(key, headers[key]);
    return options.rawBody && (xhr.responseType = "arraybuffer"), context.applyMiddleware("onRequest", {
      options,
      adapter,
      request: xhr,
      context
    }), xhr.send(options.body || null), delays && (timers.connect = setTimeout(() => timeoutRequest("ETIMEDOUT"), delays.connect)), {
      abort
    };
    function abort() {
      aborted = !0, xhr && xhr.abort();
    }
    function timeoutRequest(code) {
      timedOut = !0, xhr.abort();
      const error = new Error(code === "ESOCKETTIMEDOUT" ? `Socket timed out on request to ${options.url}` : `Connection timed out on request to ${options.url}`);
      error.code = code, context.channels.error.publish(error);
    }
    function resetTimers() {
      delays && (stopTimers(), timers.socket = setTimeout(() => timeoutRequest("ESOCKETTIMEDOUT"), delays.socket));
    }
    function stopTimers(force) {
      (force || aborted || xhr.readyState >= 2 && timers.connect) && clearTimeout(timers.connect), timers.socket && clearTimeout(timers.socket);
    }
    function onError(error) {
      if (loaded) return;
      stopTimers(!0), loaded = !0, xhr = null;
      const err = error || new Error(`Network error while attempting to reach ${options.url}`);
      err.isNetworkError = !0, err.request = options, callback(err);
    }
    function reduceResponse() {
      return {
        body: xhr.response || (xhr.responseType === "" || xhr.responseType === "text" ? xhr.responseText : ""),
        url: options.url,
        method: options.method,
        headers: parseHeaders$1(xhr.getAllResponseHeaders()),
        statusCode: xhr.status,
        statusMessage: xhr.statusText
      };
    }
    function onLoad() {
      if (!(aborted || loaded || timedOut)) {
        if (xhr.status === 0) {
          onError(new Error("Unknown XHR error"));
          return;
        }
        stopTimers(), loaded = !0, callback(null, reduceResponse());
      }
    }
  },
  getIt = (initMiddleware = [], httpRequest = httpRequester) => createRequester(initMiddleware, httpRequest),
  environment = "browser";
export { adapter, environment, getIt };
