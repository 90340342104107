export const CONTRIBUTOR = `
    name,
    "image": image.asset->url,
    "imageOptions": image
`;

export const CONTRIBUTOR_FULL = `
    name,
    email,
    position,
    description,
    instagramUrl,
    "slug": slug.current,
    "image": image.asset->url,
    "imageOptions": image
`;

export const INFO_PAGE = `
    "image": image.asset->url,
    "slug": slug.current,
    title,
    body,
    pageIcon,
    pageType,
    facetFilters,
    pairing,
    faq
`;

export const ARTICLE = `
    _id,
    publishedAt,
    "image": "https://cdn.vinborsen.se/" + image.asset->path,
    "products": products[]->{
        description,
        "product": product->productData
    },
    "slug": slug.current,
    "category": category->{
        name,
        color,
        "slug": slug.current
    },
    title,
    body,
    excerpt
`;

export const REVIEW = `
    _id,
    publishedAt,
    "product": product->productData,
    "author": author->{
        ${CONTRIBUTOR}
    },
    grade,
    price,
    vintage,
    body
`;
