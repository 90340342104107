import { getIt } from "get-it";
import { adapter, environment } from "get-it";
import { retry, jsonRequest, jsonResponse, progress, observable } from "get-it/middleware";
import { Observable, from, lastValueFrom } from "rxjs";
import { combineLatestWith, map, filter } from "rxjs/operators";
import { stegaClean } from "./_chunks-es/stegaClean.js";
var __defProp$3 = Object.defineProperty,
  __defNormalProp$3 = (obj, key, value) => key in obj ? __defProp$3(obj, key, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value
  }) : obj[key] = value,
  __publicField$3 = (obj, key, value) => (__defNormalProp$3(obj, typeof key != "symbol" ? key + "" : key, value), value);
class ClientError extends Error {
  constructor(res) {
    const props = extractErrorProps(res);
    super(props.message), __publicField$3(this, "response"), __publicField$3(this, "statusCode", 400), __publicField$3(this, "responseBody"), __publicField$3(this, "details"), Object.assign(this, props);
  }
}
class ServerError extends Error {
  constructor(res) {
    const props = extractErrorProps(res);
    super(props.message), __publicField$3(this, "response"), __publicField$3(this, "statusCode", 500), __publicField$3(this, "responseBody"), __publicField$3(this, "details"), Object.assign(this, props);
  }
}
function extractErrorProps(res) {
  const body = res.body,
    props = {
      response: res,
      statusCode: res.statusCode,
      responseBody: stringifyBody(body, res),
      message: "",
      details: void 0
    };
  if (body.error && body.message) return props.message = `${body.error} - ${body.message}`, props;
  if (isMutationError(body) || isActionError(body)) {
    const allItems = body.error.items || [],
      items = allItems.slice(0, 5).map(item => {
        var _a;
        return (_a = item.error) == null ? void 0 : _a.description;
      }).filter(Boolean);
    let itemsStr = items.length ? `:
- ${items.join(`
- `)}` : "";
    return allItems.length > 5 && (itemsStr += `
...and ${allItems.length - 5} more`), props.message = `${body.error.description}${itemsStr}`, props.details = body.error, props;
  }
  return body.error && body.error.description ? (props.message = body.error.description, props.details = body.error, props) : (props.message = body.error || body.message || httpErrorMessage(res), props);
}
function isMutationError(body) {
  return isPlainObject(body) && isPlainObject(body.error) && body.error.type === "mutationError" && typeof body.error.description == "string";
}
function isActionError(body) {
  return isPlainObject(body) && isPlainObject(body.error) && body.error.type === "actionError" && typeof body.error.description == "string";
}
function isPlainObject(obj) {
  return typeof obj == "object" && obj !== null && !Array.isArray(obj);
}
function httpErrorMessage(res) {
  const statusMessage = res.statusMessage ? ` ${res.statusMessage}` : "";
  return `${res.method}-request to ${res.url} resulted in HTTP ${res.statusCode}${statusMessage}`;
}
function stringifyBody(body, res) {
  return (res.headers["content-type"] || "").toLowerCase().indexOf("application/json") !== -1 ? JSON.stringify(body, null, 2) : body;
}
const httpError = {
    onResponse: res => {
      if (res.statusCode >= 500) throw new ServerError(res);
      if (res.statusCode >= 400) throw new ClientError(res);
      return res;
    }
  },
  printWarnings = {
    onResponse: res => {
      const warn = res.headers["x-sanity-warning"];
      return (Array.isArray(warn) ? warn : [warn]).filter(Boolean).forEach(msg => console.warn(msg)), res;
    }
  };
function defineHttpRequest(envMiddleware2) {
  return getIt([retry({
    shouldRetry
  }), ...envMiddleware2, printWarnings, jsonRequest(), jsonResponse(), progress(), httpError, observable({
    implementation: Observable
  })]);
}
function shouldRetry(err, attempt, options) {
  if (options.maxRetries === 0) return !1;
  const isSafe = options.method === "GET" || options.method === "HEAD",
    isQuery = (options.uri || options.url).startsWith("/data/query"),
    isRetriableResponse = err.response && (err.response.statusCode === 429 || err.response.statusCode === 502 || err.response.statusCode === 503);
  return (isSafe || isQuery) && isRetriableResponse ? !0 : retry.shouldRetry(err, attempt, options);
}
function getSelection(sel) {
  if (typeof sel == "string") return {
    id: sel
  };
  if (Array.isArray(sel)) return {
    query: "*[_id in $ids]",
    params: {
      ids: sel
    }
  };
  if (typeof sel == "object" && sel !== null && "query" in sel && typeof sel.query == "string") return "params" in sel && typeof sel.params == "object" && sel.params !== null ? {
    query: sel.query,
    params: sel.params
  } : {
    query: sel.query
  };
  const selectionOpts = ["* Document ID (<docId>)", "* Array of document IDs", "* Object containing `query`"].join(`
`);
  throw new Error(`Unknown selection - must be one of:

${selectionOpts}`);
}
const VALID_ASSET_TYPES = ["image", "file"],
  VALID_INSERT_LOCATIONS = ["before", "after", "replace"],
  dataset = name => {
    if (!/^(~[a-z0-9]{1}[-\w]{0,63}|[a-z0-9]{1}[-\w]{0,63})$/.test(name)) throw new Error("Datasets can only contain lowercase characters, numbers, underscores and dashes, and start with tilde, and be maximum 64 characters");
  },
  projectId = id => {
    if (!/^[-a-z0-9]+$/i.test(id)) throw new Error("`projectId` can only contain only a-z, 0-9 and dashes");
  },
  validateAssetType = type => {
    if (VALID_ASSET_TYPES.indexOf(type) === -1) throw new Error(`Invalid asset type: ${type}. Must be one of ${VALID_ASSET_TYPES.join(", ")}`);
  },
  validateObject = (op, val) => {
    if (val === null || typeof val != "object" || Array.isArray(val)) throw new Error(`${op}() takes an object of properties`);
  },
  validateDocumentId = (op, id) => {
    if (typeof id != "string" || !/^[a-z0-9_][a-z0-9_.-]{0,127}$/i.test(id) || id.includes("..")) throw new Error(`${op}(): "${id}" is not a valid document ID`);
  },
  requireDocumentId = (op, doc) => {
    if (!doc._id) throw new Error(`${op}() requires that the document contains an ID ("_id" property)`);
    validateDocumentId(op, doc._id);
  },
  validateInsert = (at, selector, items) => {
    const signature = "insert(at, selector, items)";
    if (VALID_INSERT_LOCATIONS.indexOf(at) === -1) {
      const valid = VALID_INSERT_LOCATIONS.map(loc => `"${loc}"`).join(", ");
      throw new Error(`${signature} takes an "at"-argument which is one of: ${valid}`);
    }
    if (typeof selector != "string") throw new Error(`${signature} takes a "selector"-argument which must be a string`);
    if (!Array.isArray(items)) throw new Error(`${signature} takes an "items"-argument which must be an array`);
  },
  hasDataset = config => {
    if (!config.dataset) throw new Error("`dataset` must be provided to perform queries");
    return config.dataset || "";
  },
  requestTag = tag => {
    if (typeof tag != "string" || !/^[a-z0-9._-]{1,75}$/i.test(tag)) throw new Error("Tag can only contain alphanumeric characters, underscores, dashes and dots, and be between one and 75 characters long.");
    return tag;
  };
var __defProp$2 = Object.defineProperty,
  __defNormalProp$2 = (obj, key, value) => key in obj ? __defProp$2(obj, key, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value
  }) : obj[key] = value,
  __publicField$2 = (obj, key, value) => (__defNormalProp$2(obj, typeof key != "symbol" ? key + "" : key, value), value),
  __accessCheck$7 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$7 = (obj, member, getter) => (__accessCheck$7(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$7 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$7 = (obj, member, value, setter) => (__accessCheck$7(obj, member, "write to private field"), member.set(obj, value), value);
class BasePatch {
  constructor(selection, operations = {}) {
    __publicField$2(this, "selection"), __publicField$2(this, "operations"), this.selection = selection, this.operations = operations;
  }
  /**
   * Sets the given attributes to the document. Does NOT merge objects.
   * The operation is added to the current patch, ready to be commited by `commit()`
   *
   * @param attrs - Attributes to set. To set a deep attribute, use JSONMatch, eg: \{"nested.prop": "value"\}
   */
  set(attrs) {
    return this._assign("set", attrs);
  }
  /**
   * Sets the given attributes to the document if they are not currently set. Does NOT merge objects.
   * The operation is added to the current patch, ready to be commited by `commit()`
   *
   * @param attrs - Attributes to set. To set a deep attribute, use JSONMatch, eg: \{"nested.prop": "value"\}
   */
  setIfMissing(attrs) {
    return this._assign("setIfMissing", attrs);
  }
  /**
   * Performs a "diff-match-patch" operation on the string attributes provided.
   * The operation is added to the current patch, ready to be commited by `commit()`
   *
   * @param attrs - Attributes to perform operation on. To set a deep attribute, use JSONMatch, eg: \{"nested.prop": "dmp"\}
   */
  diffMatchPatch(attrs) {
    return validateObject("diffMatchPatch", attrs), this._assign("diffMatchPatch", attrs);
  }
  /**
   * Unsets the attribute paths provided.
   * The operation is added to the current patch, ready to be commited by `commit()`
   *
   * @param attrs - Attribute paths to unset.
   */
  unset(attrs) {
    if (!Array.isArray(attrs)) throw new Error("unset(attrs) takes an array of attributes to unset, non-array given");
    return this.operations = Object.assign({}, this.operations, {
      unset: attrs
    }), this;
  }
  /**
   * Increment a numeric value. Each entry in the argument is either an attribute or a JSON path. The value may be a positive or negative integer or floating-point value. The operation will fail if target value is not a numeric value, or doesn't exist.
   *
   * @param attrs - Object of attribute paths to increment, values representing the number to increment by.
   */
  inc(attrs) {
    return this._assign("inc", attrs);
  }
  /**
   * Decrement a numeric value. Each entry in the argument is either an attribute or a JSON path. The value may be a positive or negative integer or floating-point value. The operation will fail if target value is not a numeric value, or doesn't exist.
   *
   * @param attrs - Object of attribute paths to decrement, values representing the number to decrement by.
   */
  dec(attrs) {
    return this._assign("dec", attrs);
  }
  /**
   * Provides methods for modifying arrays, by inserting, appending and replacing elements via a JSONPath expression.
   *
   * @param at - Location to insert at, relative to the given selector, or 'replace' the matched path
   * @param selector - JSONPath expression, eg `comments[-1]` or `blocks[_key=="abc123"]`
   * @param items - Array of items to insert/replace
   */
  insert(at, selector, items) {
    return validateInsert(at, selector, items), this._assign("insert", {
      [at]: selector,
      items
    });
  }
  /**
   * Append the given items to the array at the given JSONPath
   *
   * @param selector - Attribute/path to append to, eg `comments` or `person.hobbies`
   * @param items - Array of items to append to the array
   */
  append(selector, items) {
    return this.insert("after", `${selector}[-1]`, items);
  }
  /**
   * Prepend the given items to the array at the given JSONPath
   *
   * @param selector - Attribute/path to prepend to, eg `comments` or `person.hobbies`
   * @param items - Array of items to prepend to the array
   */
  prepend(selector, items) {
    return this.insert("before", `${selector}[0]`, items);
  }
  /**
   * Change the contents of an array by removing existing elements and/or adding new elements.
   *
   * @param selector - Attribute or JSONPath expression for array
   * @param start - Index at which to start changing the array (with origin 0). If greater than the length of the array, actual starting index will be set to the length of the array. If negative, will begin that many elements from the end of the array (with origin -1) and will be set to 0 if absolute value is greater than the length of the array.x
   * @param deleteCount - An integer indicating the number of old array elements to remove.
   * @param items - The elements to add to the array, beginning at the start index. If you don't specify any elements, splice() will only remove elements from the array.
   */
  splice(selector, start, deleteCount, items) {
    const delAll = typeof deleteCount > "u" || deleteCount === -1,
      startIndex = start < 0 ? start - 1 : start,
      delCount = delAll ? -1 : Math.max(0, start + deleteCount),
      delRange = startIndex < 0 && delCount >= 0 ? "" : delCount,
      rangeSelector = `${selector}[${startIndex}:${delRange}]`;
    return this.insert("replace", rangeSelector, items || []);
  }
  /**
   * Adds a revision clause, preventing the document from being patched if the `_rev` property does not match the given value
   *
   * @param rev - Revision to lock the patch to
   */
  ifRevisionId(rev) {
    return this.operations.ifRevisionID = rev, this;
  }
  /**
   * Return a plain JSON representation of the patch
   */
  serialize() {
    return {
      ...getSelection(this.selection),
      ...this.operations
    };
  }
  /**
   * Return a plain JSON representation of the patch
   */
  toJSON() {
    return this.serialize();
  }
  /**
   * Clears the patch of all operations
   */
  reset() {
    return this.operations = {}, this;
  }
  _assign(op, props, merge = !0) {
    return validateObject(op, props), this.operations = Object.assign({}, this.operations, {
      [op]: Object.assign({}, merge && this.operations[op] || {}, props)
    }), this;
  }
  _set(op, props) {
    return this._assign(op, props, !1);
  }
}
var _client$6;
const _ObservablePatch = class _ObservablePatch2 extends BasePatch {
  constructor(selection, operations, client) {
    super(selection, operations), __privateAdd$7(this, _client$6, void 0), __privateSet$7(this, _client$6, client);
  }
  /**
   * Clones the patch
   */
  clone() {
    return new _ObservablePatch2(this.selection, {
      ...this.operations
    }, __privateGet$7(this, _client$6));
  }
  commit(options) {
    if (!__privateGet$7(this, _client$6)) throw new Error("No `client` passed to patch, either provide one or pass the patch to a clients `mutate()` method");
    const returnFirst = typeof this.selection == "string",
      opts = Object.assign({
        returnFirst,
        returnDocuments: !0
      }, options);
    return __privateGet$7(this, _client$6).mutate({
      patch: this.serialize()
    }, opts);
  }
};
_client$6 = /* @__PURE__ */new WeakMap();
let ObservablePatch = _ObservablePatch;
var _client2$5;
const _Patch = class _Patch2 extends BasePatch {
  constructor(selection, operations, client) {
    super(selection, operations), __privateAdd$7(this, _client2$5, void 0), __privateSet$7(this, _client2$5, client);
  }
  /**
   * Clones the patch
   */
  clone() {
    return new _Patch2(this.selection, {
      ...this.operations
    }, __privateGet$7(this, _client2$5));
  }
  commit(options) {
    if (!__privateGet$7(this, _client2$5)) throw new Error("No `client` passed to patch, either provide one or pass the patch to a clients `mutate()` method");
    const returnFirst = typeof this.selection == "string",
      opts = Object.assign({
        returnFirst,
        returnDocuments: !0
      }, options);
    return __privateGet$7(this, _client2$5).mutate({
      patch: this.serialize()
    }, opts);
  }
};
_client2$5 = /* @__PURE__ */new WeakMap();
let Patch = _Patch;
var __defProp$1 = Object.defineProperty,
  __defNormalProp$1 = (obj, key, value) => key in obj ? __defProp$1(obj, key, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value
  }) : obj[key] = value,
  __publicField$1 = (obj, key, value) => (__defNormalProp$1(obj, typeof key != "symbol" ? key + "" : key, value), value),
  __accessCheck$6 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$6 = (obj, member, getter) => (__accessCheck$6(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$6 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$6 = (obj, member, value, setter) => (__accessCheck$6(obj, member, "write to private field"), member.set(obj, value), value);
const defaultMutateOptions = {
  returnDocuments: !1
};
class BaseTransaction {
  constructor(operations = [], transactionId) {
    __publicField$1(this, "operations"), __publicField$1(this, "trxId"), this.operations = operations, this.trxId = transactionId;
  }
  /**
   * Creates a new Sanity document. If `_id` is provided and already exists, the mutation will fail. If no `_id` is given, one will automatically be generated by the database.
   * The operation is added to the current transaction, ready to be commited by `commit()`
   *
   * @param doc - Document to create. Requires a `_type` property.
   */
  create(doc) {
    return validateObject("create", doc), this._add({
      create: doc
    });
  }
  /**
   * Creates a new Sanity document. If a document with the same `_id` already exists, the create operation will be ignored.
   * The operation is added to the current transaction, ready to be commited by `commit()`
   *
   * @param doc - Document to create if it does not already exist. Requires `_id` and `_type` properties.
   */
  createIfNotExists(doc) {
    const op = "createIfNotExists";
    return validateObject(op, doc), requireDocumentId(op, doc), this._add({
      [op]: doc
    });
  }
  /**
   * Creates a new Sanity document, or replaces an existing one if the same `_id` is already used.
   * The operation is added to the current transaction, ready to be commited by `commit()`
   *
   * @param doc - Document to create or replace. Requires `_id` and `_type` properties.
   */
  createOrReplace(doc) {
    const op = "createOrReplace";
    return validateObject(op, doc), requireDocumentId(op, doc), this._add({
      [op]: doc
    });
  }
  /**
   * Deletes the document with the given document ID
   * The operation is added to the current transaction, ready to be commited by `commit()`
   *
   * @param documentId - Document ID to delete
   */
  delete(documentId) {
    return validateDocumentId("delete", documentId), this._add({
      delete: {
        id: documentId
      }
    });
  }
  transactionId(id) {
    return id ? (this.trxId = id, this) : this.trxId;
  }
  /**
   * Return a plain JSON representation of the transaction
   */
  serialize() {
    return [...this.operations];
  }
  /**
   * Return a plain JSON representation of the transaction
   */
  toJSON() {
    return this.serialize();
  }
  /**
   * Clears the transaction of all operations
   */
  reset() {
    return this.operations = [], this;
  }
  _add(mut) {
    return this.operations.push(mut), this;
  }
}
var _client$5;
const _Transaction = class _Transaction2 extends BaseTransaction {
  constructor(operations, client, transactionId) {
    super(operations, transactionId), __privateAdd$6(this, _client$5, void 0), __privateSet$6(this, _client$5, client);
  }
  /**
   * Clones the transaction
   */
  clone() {
    return new _Transaction2([...this.operations], __privateGet$6(this, _client$5), this.trxId);
  }
  commit(options) {
    if (!__privateGet$6(this, _client$5)) throw new Error("No `client` passed to transaction, either provide one or pass the transaction to a clients `mutate()` method");
    return __privateGet$6(this, _client$5).mutate(this.serialize(), Object.assign({
      transactionId: this.trxId
    }, defaultMutateOptions, options || {}));
  }
  patch(patchOrDocumentId, patchOps) {
    const isBuilder = typeof patchOps == "function";
    if (typeof patchOrDocumentId != "string" && patchOrDocumentId instanceof Patch) return this._add({
      patch: patchOrDocumentId.serialize()
    });
    if (isBuilder) {
      const patch = patchOps(new Patch(patchOrDocumentId, {}, __privateGet$6(this, _client$5)));
      if (!(patch instanceof Patch)) throw new Error("function passed to `patch()` must return the patch");
      return this._add({
        patch: patch.serialize()
      });
    }
    return this._add({
      patch: {
        id: patchOrDocumentId,
        ...patchOps
      }
    });
  }
};
_client$5 = /* @__PURE__ */new WeakMap();
let Transaction = _Transaction;
var _client2$4;
const _ObservableTransaction = class _ObservableTransaction2 extends BaseTransaction {
  constructor(operations, client, transactionId) {
    super(operations, transactionId), __privateAdd$6(this, _client2$4, void 0), __privateSet$6(this, _client2$4, client);
  }
  /**
   * Clones the transaction
   */
  clone() {
    return new _ObservableTransaction2([...this.operations], __privateGet$6(this, _client2$4), this.trxId);
  }
  commit(options) {
    if (!__privateGet$6(this, _client2$4)) throw new Error("No `client` passed to transaction, either provide one or pass the transaction to a clients `mutate()` method");
    return __privateGet$6(this, _client2$4).mutate(this.serialize(), Object.assign({
      transactionId: this.trxId
    }, defaultMutateOptions, options || {}));
  }
  patch(patchOrDocumentId, patchOps) {
    const isBuilder = typeof patchOps == "function";
    if (typeof patchOrDocumentId != "string" && patchOrDocumentId instanceof ObservablePatch) return this._add({
      patch: patchOrDocumentId.serialize()
    });
    if (isBuilder) {
      const patch = patchOps(new ObservablePatch(patchOrDocumentId, {}, __privateGet$6(this, _client2$4)));
      if (!(patch instanceof ObservablePatch)) throw new Error("function passed to `patch()` must return the patch");
      return this._add({
        patch: patch.serialize()
      });
    }
    return this._add({
      patch: {
        id: patchOrDocumentId,
        ...patchOps
      }
    });
  }
};
_client2$4 = /* @__PURE__ */new WeakMap();
let ObservableTransaction = _ObservableTransaction;
const BASE_URL = "https://www.sanity.io/help/";
function generateHelpUrl(slug) {
  return BASE_URL + slug;
}
function once(fn) {
  let didCall = !1,
    returnValue;
  return (...args) => (didCall || (returnValue = fn(...args), didCall = !0), returnValue);
}
const createWarningPrinter = message =>
  // eslint-disable-next-line no-console
  once((...args) => console.warn(message.join(" "), ...args)),
  printCdnAndWithCredentialsWarning = createWarningPrinter(["Because you set `withCredentials` to true, we will override your `useCdn`", "setting to be false since (cookie-based) credentials are never set on the CDN"]),
  printCdnWarning = createWarningPrinter(["Since you haven't set a value for `useCdn`, we will deliver content using our", "global, edge-cached API-CDN. If you wish to have content delivered faster, set", "`useCdn: false` to use the Live API. Note: You may incur higher costs using the live API."]),
  printCdnPreviewDraftsWarning = createWarningPrinter(["The Sanity client is configured with the `perspective` set to `previewDrafts`, which doesn't support the API-CDN.", "The Live API will be used instead. Set `useCdn: false` in your configuration to hide this warning."]),
  printBrowserTokenWarning = createWarningPrinter(["You have configured Sanity client to use a token in the browser. This may cause unintentional security issues.", `See ${generateHelpUrl("js-client-browser-token")} for more information and how to hide this warning.`]),
  printNoApiVersionSpecifiedWarning = createWarningPrinter(["Using the Sanity client without specifying an API version is deprecated.", `See ${generateHelpUrl("js-client-api-version")}`]),
  printNoDefaultExport = createWarningPrinter(["The default export of @sanity/client has been deprecated. Use the named export `createClient` instead."]),
  defaultCdnHost = "apicdn.sanity.io",
  defaultConfig = {
    apiHost: "https://api.sanity.io",
    apiVersion: "1",
    useProjectHostname: !0,
    stega: {
      enabled: !1
    }
  },
  LOCALHOSTS = ["localhost", "127.0.0.1", "0.0.0.0"],
  isLocal = host => LOCALHOSTS.indexOf(host) !== -1;
function validateApiVersion(apiVersion) {
  if (apiVersion === "1" || apiVersion === "X") return;
  const apiDate = new Date(apiVersion);
  if (!(/^\d{4}-\d{2}-\d{2}$/.test(apiVersion) && apiDate instanceof Date && apiDate.getTime() > 0)) throw new Error("Invalid API version string, expected `1` or date in format `YYYY-MM-DD`");
}
const validateApiPerspective = function (perspective) {
    switch (perspective) {
      case "previewDrafts":
      case "published":
      case "raw":
        return;
      default:
        throw new TypeError("Invalid API perspective string, expected `published`, `previewDrafts` or `raw`");
    }
  },
  initConfig = (config, prevConfig) => {
    const specifiedConfig = {
      ...prevConfig,
      ...config,
      stega: {
        ...(typeof prevConfig.stega == "boolean" ? {
          enabled: prevConfig.stega
        } : prevConfig.stega || defaultConfig.stega),
        ...(typeof config.stega == "boolean" ? {
          enabled: config.stega
        } : config.stega || {})
      }
    };
    specifiedConfig.apiVersion || printNoApiVersionSpecifiedWarning();
    const newConfig = {
        ...defaultConfig,
        ...specifiedConfig
      },
      projectBased = newConfig.useProjectHostname;
    if (typeof Promise > "u") {
      const helpUrl = generateHelpUrl("js-client-promise-polyfill");
      throw new Error(`No native Promise-implementation found, polyfill needed - see ${helpUrl}`);
    }
    if (projectBased && !newConfig.projectId) throw new Error("Configuration must contain `projectId`");
    if (typeof newConfig.perspective == "string" && validateApiPerspective(newConfig.perspective), "encodeSourceMap" in newConfig) throw new Error("It looks like you're using options meant for '@sanity/preview-kit/client'. 'encodeSourceMap' is not supported in '@sanity/client'. Did you mean 'stega.enabled'?");
    if ("encodeSourceMapAtPath" in newConfig) throw new Error("It looks like you're using options meant for '@sanity/preview-kit/client'. 'encodeSourceMapAtPath' is not supported in '@sanity/client'. Did you mean 'stega.filter'?");
    if (typeof newConfig.stega.enabled != "boolean") throw new Error(`stega.enabled must be a boolean, received ${newConfig.stega.enabled}`);
    if (newConfig.stega.enabled && newConfig.stega.studioUrl === void 0) throw new Error("stega.studioUrl must be defined when stega.enabled is true");
    if (newConfig.stega.enabled && typeof newConfig.stega.studioUrl != "string" && typeof newConfig.stega.studioUrl != "function") throw new Error(`stega.studioUrl must be a string or a function, received ${newConfig.stega.studioUrl}`);
    const isBrowser = typeof window < "u" && window.location && window.location.hostname,
      isLocalhost = isBrowser && isLocal(window.location.hostname);
    isBrowser && isLocalhost && newConfig.token && newConfig.ignoreBrowserTokenWarning !== !0 ? printBrowserTokenWarning() : typeof newConfig.useCdn > "u" && printCdnWarning(), projectBased && projectId(newConfig.projectId), newConfig.dataset && dataset(newConfig.dataset), "requestTagPrefix" in newConfig && (newConfig.requestTagPrefix = newConfig.requestTagPrefix ? requestTag(newConfig.requestTagPrefix).replace(/\.+$/, "") : void 0), newConfig.apiVersion = `${newConfig.apiVersion}`.replace(/^v/, ""), newConfig.isDefaultApi = newConfig.apiHost === defaultConfig.apiHost, newConfig.useCdn === !0 && newConfig.withCredentials && printCdnAndWithCredentialsWarning(), newConfig.useCdn = newConfig.useCdn !== !1 && !newConfig.withCredentials, validateApiVersion(newConfig.apiVersion);
    const hostParts = newConfig.apiHost.split("://", 2),
      protocol = hostParts[0],
      host = hostParts[1],
      cdnHost = newConfig.isDefaultApi ? defaultCdnHost : host;
    return newConfig.useProjectHostname ? (newConfig.url = `${protocol}://${newConfig.projectId}.${host}/v${newConfig.apiVersion}`, newConfig.cdnUrl = `${protocol}://${newConfig.projectId}.${cdnHost}/v${newConfig.apiVersion}`) : (newConfig.url = `${newConfig.apiHost}/v${newConfig.apiVersion}`, newConfig.cdnUrl = newConfig.url), newConfig;
  },
  projectHeader = "X-Sanity-Project-ID";
function requestOptions(config, overrides = {}) {
  const headers = {},
    token = overrides.token || config.token;
  token && (headers.Authorization = `Bearer ${token}`), !overrides.useGlobalApi && !config.useProjectHostname && config.projectId && (headers[projectHeader] = config.projectId);
  const withCredentials = !!(typeof overrides.withCredentials > "u" ? config.token || config.withCredentials : overrides.withCredentials),
    timeout = typeof overrides.timeout > "u" ? config.timeout : overrides.timeout;
  return Object.assign({}, overrides, {
    headers: Object.assign({}, headers, overrides.headers || {}),
    timeout: typeof timeout > "u" ? 5 * 60 * 1e3 : timeout,
    proxy: overrides.proxy || config.proxy,
    json: !0,
    withCredentials,
    fetch: typeof overrides.fetch == "object" && typeof config.fetch == "object" ? {
      ...config.fetch,
      ...overrides.fetch
    } : overrides.fetch || config.fetch
  });
}
const encodeQueryString = ({
    query,
    params = {},
    options = {}
  }) => {
    const searchParams = new URLSearchParams(),
      {
        tag,
        returnQuery,
        ...opts
      } = options;
    tag && searchParams.append("tag", tag), searchParams.append("query", query);
    for (const [key, value] of Object.entries(params)) searchParams.append(`$${key}`, JSON.stringify(value));
    for (const [key, value] of Object.entries(opts)) value && searchParams.append(key, `${value}`);
    return returnQuery === !1 && searchParams.append("returnQuery", "false"), `?${searchParams}`;
  },
  excludeFalsey = (param, defValue) => param === !1 ? void 0 : typeof param > "u" ? defValue : param,
  getMutationQuery = (options = {}) => ({
    dryRun: options.dryRun,
    returnIds: !0,
    returnDocuments: excludeFalsey(options.returnDocuments, !0),
    visibility: options.visibility || "sync",
    autoGenerateArrayKeys: options.autoGenerateArrayKeys,
    skipCrossDatasetReferenceValidation: options.skipCrossDatasetReferenceValidation
  }),
  isResponse = event => event.type === "response",
  getBody = event => event.body,
  indexBy = (docs, attr) => docs.reduce((indexed, doc) => (indexed[attr(doc)] = doc, indexed), /* @__PURE__ */Object.create(null)),
  getQuerySizeLimit = 11264;
function _fetch(client, httpRequest, _stega, query, _params = {}, options = {}) {
  const stega = "stega" in options ? {
      ...(_stega || {}),
      ...(typeof options.stega == "boolean" ? {
        enabled: options.stega
      } : options.stega || {})
    } : _stega,
    params = stega.enabled ? stegaClean(_params) : _params,
    mapResponse = options.filterResponse === !1 ? res => res : res => res.result,
    {
      cache,
      next,
      ...opts
    } = {
      // Opt out of setting a `signal` on an internal `fetch` if one isn't provided.
      // This is necessary in React Server Components to avoid opting out of Request Memoization.
      useAbortSignal: typeof options.signal < "u",
      // Set `resultSourceMap' when stega is enabled, as it's required for encoding.
      resultSourceMap: stega.enabled ? "withKeyArraySelector" : options.resultSourceMap,
      ...options,
      // Default to not returning the query, unless `filterResponse` is `false`,
      // or `returnQuery` is explicitly set. `true` is the default in Content Lake, so skip if truthy
      returnQuery: options.filterResponse === !1 && options.returnQuery !== !1
    },
    reqOpts = typeof cache < "u" || typeof next < "u" ? {
      ...opts,
      fetch: {
        cache,
        next
      }
    } : opts,
    $request = _dataRequest(client, httpRequest, "query", {
      query,
      params
    }, reqOpts);
  return stega.enabled ? $request.pipe(combineLatestWith(from(import("./_chunks-es/stegaEncodeSourceMap.js").then(function (n) {
    return n.stegaEncodeSourceMap$1;
  }).then(({
    stegaEncodeSourceMap
  }) => stegaEncodeSourceMap))), map(([res, stegaEncodeSourceMap]) => {
    const result = stegaEncodeSourceMap(res.result, res.resultSourceMap, stega);
    return mapResponse({
      ...res,
      result
    });
  })) : $request.pipe(map(mapResponse));
}
function _getDocument(client, httpRequest, id, opts = {}) {
  const options = {
    uri: _getDataUrl(client, "doc", id),
    json: !0,
    tag: opts.tag
  };
  return _requestObservable(client, httpRequest, options).pipe(filter(isResponse), map(event => event.body.documents && event.body.documents[0]));
}
function _getDocuments(client, httpRequest, ids, opts = {}) {
  const options = {
    uri: _getDataUrl(client, "doc", ids.join(",")),
    json: !0,
    tag: opts.tag
  };
  return _requestObservable(client, httpRequest, options).pipe(filter(isResponse), map(event => {
    const indexed = indexBy(event.body.documents || [], doc => doc._id);
    return ids.map(id => indexed[id] || null);
  }));
}
function _createIfNotExists(client, httpRequest, doc, options) {
  return requireDocumentId("createIfNotExists", doc), _create(client, httpRequest, doc, "createIfNotExists", options);
}
function _createOrReplace(client, httpRequest, doc, options) {
  return requireDocumentId("createOrReplace", doc), _create(client, httpRequest, doc, "createOrReplace", options);
}
function _delete(client, httpRequest, selection, options) {
  return _dataRequest(client, httpRequest, "mutate", {
    mutations: [{
      delete: getSelection(selection)
    }]
  }, options);
}
function _mutate(client, httpRequest, mutations, options) {
  let mut;
  mutations instanceof Patch || mutations instanceof ObservablePatch ? mut = {
    patch: mutations.serialize()
  } : mutations instanceof Transaction || mutations instanceof ObservableTransaction ? mut = mutations.serialize() : mut = mutations;
  const muts = Array.isArray(mut) ? mut : [mut],
    transactionId = options && options.transactionId || void 0;
  return _dataRequest(client, httpRequest, "mutate", {
    mutations: muts,
    transactionId
  }, options);
}
function _action(client, httpRequest, actions, options) {
  const acts = Array.isArray(actions) ? actions : [actions],
    transactionId = options && options.transactionId || void 0,
    skipCrossDatasetReferenceValidation = options && options.skipCrossDatasetReferenceValidation || void 0,
    dryRun = options && options.dryRun || void 0;
  return _dataRequest(client, httpRequest, "actions", {
    actions: acts,
    transactionId,
    skipCrossDatasetReferenceValidation,
    dryRun
  }, options);
}
function _dataRequest(client, httpRequest, endpoint, body, options = {}) {
  const isMutation = endpoint === "mutate",
    isAction = endpoint === "actions",
    isQuery = endpoint === "query",
    strQuery = isMutation || isAction ? "" : encodeQueryString(body),
    useGet = !isMutation && !isAction && strQuery.length < getQuerySizeLimit,
    stringQuery = useGet ? strQuery : "",
    returnFirst = options.returnFirst,
    {
      timeout,
      token,
      tag,
      headers,
      returnQuery,
      lastLiveEventId
    } = options,
    uri = _getDataUrl(client, endpoint, stringQuery),
    reqOptions = {
      method: useGet ? "GET" : "POST",
      uri,
      json: !0,
      body: useGet ? void 0 : body,
      query: isMutation && getMutationQuery(options),
      timeout,
      headers,
      token,
      tag,
      returnQuery,
      perspective: options.perspective,
      resultSourceMap: options.resultSourceMap,
      lastLiveEventId: Array.isArray(lastLiveEventId) ? lastLiveEventId[0] : lastLiveEventId,
      canUseCdn: isQuery,
      signal: options.signal,
      fetch: options.fetch,
      useAbortSignal: options.useAbortSignal,
      useCdn: options.useCdn
    };
  return _requestObservable(client, httpRequest, reqOptions).pipe(filter(isResponse), map(getBody), map(res => {
    if (!isMutation) return res;
    const results = res.results || [];
    if (options.returnDocuments) return returnFirst ? results[0] && results[0].document : results.map(mut => mut.document);
    const key = returnFirst ? "documentId" : "documentIds",
      ids = returnFirst ? results[0] && results[0].id : results.map(mut => mut.id);
    return {
      transactionId: res.transactionId,
      results,
      [key]: ids
    };
  }));
}
function _create(client, httpRequest, doc, op, options = {}) {
  const mutation = {
      [op]: doc
    },
    opts = Object.assign({
      returnFirst: !0,
      returnDocuments: !0
    }, options);
  return _dataRequest(client, httpRequest, "mutate", {
    mutations: [mutation]
  }, opts);
}
function _requestObservable(client, httpRequest, options) {
  var _a, _b;
  const uri = options.url || options.uri,
    config = client.config(),
    canUseCdn = typeof options.canUseCdn > "u" ? ["GET", "HEAD"].indexOf(options.method || "GET") >= 0 && uri.indexOf("/data/") === 0 : options.canUseCdn;
  let useCdn = ((_a = options.useCdn) != null ? _a : config.useCdn) && canUseCdn;
  const tag = options.tag && config.requestTagPrefix ? [config.requestTagPrefix, options.tag].join(".") : options.tag || config.requestTagPrefix;
  if (tag && options.tag !== null && (options.query = {
    tag: requestTag(tag),
    ...options.query
  }), ["GET", "HEAD", "POST"].indexOf(options.method || "GET") >= 0 && uri.indexOf("/data/query/") === 0) {
    const resultSourceMap = (_b = options.resultSourceMap) != null ? _b : config.resultSourceMap;
    resultSourceMap !== void 0 && resultSourceMap !== !1 && (options.query = {
      resultSourceMap,
      ...options.query
    });
    const perspective = options.perspective || config.perspective;
    typeof perspective == "string" && perspective !== "raw" && (validateApiPerspective(perspective), options.query = {
      perspective,
      ...options.query
    }, perspective === "previewDrafts" && useCdn && (useCdn = !1, printCdnPreviewDraftsWarning())), options.lastLiveEventId && (options.query = {
      ...options.query,
      lastLiveEventId: options.lastLiveEventId
    }), options.returnQuery === !1 && (options.query = {
      returnQuery: "false",
      ...options.query
    });
  }
  const reqOptions = requestOptions(config, Object.assign({}, options, {
      url: _getUrl(client, uri, useCdn)
    })),
    request = new Observable(subscriber => httpRequest(reqOptions, config.requester).subscribe(subscriber));
  return options.signal ? request.pipe(_withAbortSignal(options.signal)) : request;
}
function _request(client, httpRequest, options) {
  return _requestObservable(client, httpRequest, options).pipe(filter(event => event.type === "response"), map(event => event.body));
}
function _getDataUrl(client, operation, path) {
  const config = client.config(),
    catalog = hasDataset(config),
    baseUri = `/${operation}/${catalog}`;
  return `/data${path ? `${baseUri}/${path}` : baseUri}`.replace(/\/($|\?)/, "$1");
}
function _getUrl(client, uri, canUseCdn = !1) {
  const {
    url,
    cdnUrl
  } = client.config();
  return `${canUseCdn ? cdnUrl : url}/${uri.replace(/^\//, "")}`;
}
function _withAbortSignal(signal) {
  return input => new Observable(observer => {
    const abort = () => observer.error(_createAbortError(signal));
    if (signal && signal.aborted) {
      abort();
      return;
    }
    const subscription = input.subscribe(observer);
    return signal.addEventListener("abort", abort), () => {
      signal.removeEventListener("abort", abort), subscription.unsubscribe();
    };
  });
}
const isDomExceptionSupported = !!globalThis.DOMException;
function _createAbortError(signal) {
  var _a, _b;
  if (isDomExceptionSupported) return new DOMException((_a = signal == null ? void 0 : signal.reason) != null ? _a : "The operation was aborted.", "AbortError");
  const error = new Error((_b = signal == null ? void 0 : signal.reason) != null ? _b : "The operation was aborted.");
  return error.name = "AbortError", error;
}
var __accessCheck$5 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$5 = (obj, member, getter) => (__accessCheck$5(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$5 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$5 = (obj, member, value, setter) => (__accessCheck$5(obj, member, "write to private field"), member.set(obj, value), value),
  _client$4,
  _httpRequest$4;
class ObservableAssetsClient {
  constructor(client, httpRequest) {
    __privateAdd$5(this, _client$4, void 0), __privateAdd$5(this, _httpRequest$4, void 0), __privateSet$5(this, _client$4, client), __privateSet$5(this, _httpRequest$4, httpRequest);
  }
  upload(assetType, body, options) {
    return _upload(__privateGet$5(this, _client$4), __privateGet$5(this, _httpRequest$4), assetType, body, options);
  }
}
_client$4 = /* @__PURE__ */new WeakMap(), _httpRequest$4 = /* @__PURE__ */new WeakMap();
var _client2$3, _httpRequest2$4;
class AssetsClient {
  constructor(client, httpRequest) {
    __privateAdd$5(this, _client2$3, void 0), __privateAdd$5(this, _httpRequest2$4, void 0), __privateSet$5(this, _client2$3, client), __privateSet$5(this, _httpRequest2$4, httpRequest);
  }
  upload(assetType, body, options) {
    const observable2 = _upload(__privateGet$5(this, _client2$3), __privateGet$5(this, _httpRequest2$4), assetType, body, options);
    return lastValueFrom(observable2.pipe(filter(event => event.type === "response"), map(event => event.body.document)));
  }
}
_client2$3 = /* @__PURE__ */new WeakMap(), _httpRequest2$4 = /* @__PURE__ */new WeakMap();
function _upload(client, httpRequest, assetType, body, opts = {}) {
  validateAssetType(assetType);
  let meta = opts.extract || void 0;
  meta && !meta.length && (meta = ["none"]);
  const dataset2 = hasDataset(client.config()),
    assetEndpoint = assetType === "image" ? "images" : "files",
    options = optionsFromFile(opts, body),
    {
      tag,
      label,
      title,
      description,
      creditLine,
      filename,
      source
    } = options,
    query = {
      label,
      title,
      description,
      filename,
      meta,
      creditLine
    };
  return source && (query.sourceId = source.id, query.sourceName = source.name, query.sourceUrl = source.url), _requestObservable(client, httpRequest, {
    tag,
    method: "POST",
    timeout: options.timeout || 0,
    uri: `/assets/${assetEndpoint}/${dataset2}`,
    headers: options.contentType ? {
      "Content-Type": options.contentType
    } : {},
    query,
    body
  });
}
function optionsFromFile(opts, file) {
  return typeof File > "u" || !(file instanceof File) ? opts : Object.assign({
    filename: opts.preserveFilename === !1 ? void 0 : file.name,
    contentType: file.type
  }, opts);
}
var defaults = (obj, defaults2) => Object.keys(defaults2).concat(Object.keys(obj)).reduce((target, prop) => (target[prop] = typeof obj[prop] > "u" ? defaults2[prop] : obj[prop], target), {});
const pick = (obj, props) => props.reduce((selection, prop) => (typeof obj[prop] > "u" || (selection[prop] = obj[prop]), selection), {}),
  MAX_URL_LENGTH = 14800,
  possibleOptions = ["includePreviousRevision", "includeResult", "visibility", "effectFormat", "tag"],
  defaultOptions = {
    includeResult: !0
  };
function _listen(query, params, opts = {}) {
  const {
      url,
      token,
      withCredentials,
      requestTagPrefix
    } = this.config(),
    tag = opts.tag && requestTagPrefix ? [requestTagPrefix, opts.tag].join(".") : opts.tag,
    options = {
      ...defaults(opts, defaultOptions),
      tag
    },
    listenOpts = pick(options, possibleOptions),
    qs = encodeQueryString({
      query,
      params,
      options: {
        tag,
        ...listenOpts
      }
    }),
    uri = `${url}${_getDataUrl(this, "listen", qs)}`;
  if (uri.length > MAX_URL_LENGTH) return new Observable(observer => observer.error(new Error("Query too large for listener")));
  const listenFor = options.events ? options.events : ["mutation"],
    shouldEmitReconnect = listenFor.indexOf("reconnect") !== -1,
    esOptions = {};
  return (token || withCredentials) && (esOptions.withCredentials = !0), token && (esOptions.headers = {
    Authorization: `Bearer ${token}`
  }), new Observable(observer => {
    let es,
      reconnectTimer,
      stopped = !1,
      unsubscribed = !1;
    open();
    function onError() {
      stopped || (emitReconnect(), !stopped && es.readyState === es.CLOSED && (unsubscribe(), clearTimeout(reconnectTimer), reconnectTimer = setTimeout(open, 100)));
    }
    function onChannelError(err) {
      observer.error(cooerceError(err));
    }
    function onMessage(evt) {
      const event = parseEvent$1(evt);
      return event instanceof Error ? observer.error(event) : observer.next(event);
    }
    function onDisconnect() {
      stopped = !0, unsubscribe(), observer.complete();
    }
    function unsubscribe() {
      es && (es.removeEventListener("error", onError), es.removeEventListener("channelError", onChannelError), es.removeEventListener("disconnect", onDisconnect), listenFor.forEach(type => es.removeEventListener(type, onMessage)), es.close());
    }
    function emitReconnect() {
      shouldEmitReconnect && observer.next({
        type: "reconnect"
      });
    }
    async function getEventSource() {
      const {
        default: EventSource2
      } = await import("@sanity/eventsource");
      if (unsubscribed) return;
      const evs = new EventSource2(uri, esOptions);
      return evs.addEventListener("error", onError), evs.addEventListener("channelError", onChannelError), evs.addEventListener("disconnect", onDisconnect), listenFor.forEach(type => evs.addEventListener(type, onMessage)), evs;
    }
    function open() {
      getEventSource().then(eventSource => {
        eventSource && (es = eventSource, unsubscribed && unsubscribe());
      }).catch(reason => {
        observer.error(reason), stop();
      });
    }
    function stop() {
      stopped = !0, unsubscribe(), unsubscribed = !0;
    }
    return stop;
  });
}
function parseEvent$1(event) {
  try {
    const data = event.data && JSON.parse(event.data) || {};
    return Object.assign({
      type: event.type
    }, data);
  } catch (err) {
    return err;
  }
}
function cooerceError(err) {
  if (err instanceof Error) return err;
  const evt = parseEvent$1(err);
  return evt instanceof Error ? evt : new Error(extractErrorMessage(evt));
}
function extractErrorMessage(err) {
  return err.error ? err.error.description ? err.error.description : typeof err.error == "string" ? err.error : JSON.stringify(err.error, null, 2) : err.message || "Unknown listener error";
}
var __accessCheck$4 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$4 = (obj, member, getter) => (__accessCheck$4(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$4 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$4 = (obj, member, value, setter) => (__accessCheck$4(obj, member, "write to private field"), member.set(obj, value), value);
const requiredApiVersion = "2021-03-26";
var _client$3;
class LiveClient {
  constructor(client) {
    __privateAdd$4(this, _client$3, void 0), __privateSet$4(this, _client$3, client);
  }
  /**
   * Requires `apiVersion` to be `2021-03-26` or later.
   */
  events() {
    const apiVersion = __privateGet$4(this, _client$3).config().apiVersion.replace(/^v/, "");
    if (apiVersion !== "X" && apiVersion < requiredApiVersion) throw new Error(`The live events API requires API version ${requiredApiVersion} or later. The current API version is ${apiVersion}. Please update your API version to use this feature.`);
    const path = _getDataUrl(__privateGet$4(this, _client$3), "live/events"),
      url = new URL(__privateGet$4(this, _client$3).getUrl(path, !1)),
      listenFor = ["restart", "message"];
    return new Observable(observer => {
      let es,
        reconnectTimer,
        stopped = !1,
        unsubscribed = !1;
      open();
      function onError(evt) {
        if (!stopped) {
          if ("data" in evt) {
            const event = parseEvent(evt);
            observer.error(new Error(event.message, {
              cause: event
            }));
          }
          es.readyState === es.CLOSED && (unsubscribe(), clearTimeout(reconnectTimer), reconnectTimer = setTimeout(open, 100));
        }
      }
      function onMessage(evt) {
        const event = parseEvent(evt);
        return event instanceof Error ? observer.error(event) : observer.next(event);
      }
      function unsubscribe() {
        if (es) {
          es.removeEventListener("error", onError);
          for (const type of listenFor) es.removeEventListener(type, onMessage);
          es.close();
        }
      }
      async function getEventSource() {
        const EventSourceImplementation = typeof EventSource > "u" ? (await import("@sanity/eventsource")).default : EventSource;
        if (unsubscribed) return;
        const evs = new EventSourceImplementation(url.toString());
        evs.addEventListener("error", onError);
        for (const type of listenFor) evs.addEventListener(type, onMessage);
        return evs;
      }
      function open() {
        getEventSource().then(eventSource => {
          eventSource && (es = eventSource, unsubscribed && unsubscribe());
        }).catch(reason => {
          observer.error(reason), stop();
        });
      }
      function stop() {
        stopped = !0, unsubscribe(), unsubscribed = !0;
      }
      return stop;
    });
  }
}
_client$3 = /* @__PURE__ */new WeakMap();
function parseEvent(event) {
  try {
    const data = event.data && JSON.parse(event.data) || {};
    return {
      type: event.type,
      id: event.lastEventId,
      ...data
    };
  } catch (err) {
    return err;
  }
}
var __accessCheck$3 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$3 = (obj, member, getter) => (__accessCheck$3(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$3 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$3 = (obj, member, value, setter) => (__accessCheck$3(obj, member, "write to private field"), member.set(obj, value), value),
  _client$2,
  _httpRequest$3;
class ObservableDatasetsClient {
  constructor(client, httpRequest) {
    __privateAdd$3(this, _client$2, void 0), __privateAdd$3(this, _httpRequest$3, void 0), __privateSet$3(this, _client$2, client), __privateSet$3(this, _httpRequest$3, httpRequest);
  }
  /**
   * Create a new dataset with the given name
   *
   * @param name - Name of the dataset to create
   * @param options - Options for the dataset
   */
  create(name, options) {
    return _modify(__privateGet$3(this, _client$2), __privateGet$3(this, _httpRequest$3), "PUT", name, options);
  }
  /**
   * Edit a dataset with the given name
   *
   * @param name - Name of the dataset to edit
   * @param options - New options for the dataset
   */
  edit(name, options) {
    return _modify(__privateGet$3(this, _client$2), __privateGet$3(this, _httpRequest$3), "PATCH", name, options);
  }
  /**
   * Delete a dataset with the given name
   *
   * @param name - Name of the dataset to delete
   */
  delete(name) {
    return _modify(__privateGet$3(this, _client$2), __privateGet$3(this, _httpRequest$3), "DELETE", name);
  }
  /**
   * Fetch a list of datasets for the configured project
   */
  list() {
    return _request(__privateGet$3(this, _client$2), __privateGet$3(this, _httpRequest$3), {
      uri: "/datasets",
      tag: null
    });
  }
}
_client$2 = /* @__PURE__ */new WeakMap(), _httpRequest$3 = /* @__PURE__ */new WeakMap();
var _client2$2, _httpRequest2$3;
class DatasetsClient {
  constructor(client, httpRequest) {
    __privateAdd$3(this, _client2$2, void 0), __privateAdd$3(this, _httpRequest2$3, void 0), __privateSet$3(this, _client2$2, client), __privateSet$3(this, _httpRequest2$3, httpRequest);
  }
  /**
   * Create a new dataset with the given name
   *
   * @param name - Name of the dataset to create
   * @param options - Options for the dataset
   */
  create(name, options) {
    return lastValueFrom(_modify(__privateGet$3(this, _client2$2), __privateGet$3(this, _httpRequest2$3), "PUT", name, options));
  }
  /**
   * Edit a dataset with the given name
   *
   * @param name - Name of the dataset to edit
   * @param options - New options for the dataset
   */
  edit(name, options) {
    return lastValueFrom(_modify(__privateGet$3(this, _client2$2), __privateGet$3(this, _httpRequest2$3), "PATCH", name, options));
  }
  /**
   * Delete a dataset with the given name
   *
   * @param name - Name of the dataset to delete
   */
  delete(name) {
    return lastValueFrom(_modify(__privateGet$3(this, _client2$2), __privateGet$3(this, _httpRequest2$3), "DELETE", name));
  }
  /**
   * Fetch a list of datasets for the configured project
   */
  list() {
    return lastValueFrom(_request(__privateGet$3(this, _client2$2), __privateGet$3(this, _httpRequest2$3), {
      uri: "/datasets",
      tag: null
    }));
  }
}
_client2$2 = /* @__PURE__ */new WeakMap(), _httpRequest2$3 = /* @__PURE__ */new WeakMap();
function _modify(client, httpRequest, method, name, options) {
  return dataset(name), _request(client, httpRequest, {
    method,
    uri: `/datasets/${name}`,
    body: options,
    tag: null
  });
}
var __accessCheck$2 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$2 = (obj, member, getter) => (__accessCheck$2(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$2 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$2 = (obj, member, value, setter) => (__accessCheck$2(obj, member, "write to private field"), member.set(obj, value), value),
  _client$1,
  _httpRequest$2;
class ObservableProjectsClient {
  constructor(client, httpRequest) {
    __privateAdd$2(this, _client$1, void 0), __privateAdd$2(this, _httpRequest$2, void 0), __privateSet$2(this, _client$1, client), __privateSet$2(this, _httpRequest$2, httpRequest);
  }
  list(options) {
    const uri = (options == null ? void 0 : options.includeMembers) === !1 ? "/projects?includeMembers=false" : "/projects";
    return _request(__privateGet$2(this, _client$1), __privateGet$2(this, _httpRequest$2), {
      uri
    });
  }
  /**
   * Fetch a project by project ID
   *
   * @param projectId - ID of the project to fetch
   */
  getById(projectId2) {
    return _request(__privateGet$2(this, _client$1), __privateGet$2(this, _httpRequest$2), {
      uri: `/projects/${projectId2}`
    });
  }
}
_client$1 = /* @__PURE__ */new WeakMap(), _httpRequest$2 = /* @__PURE__ */new WeakMap();
var _client2$1, _httpRequest2$2;
class ProjectsClient {
  constructor(client, httpRequest) {
    __privateAdd$2(this, _client2$1, void 0), __privateAdd$2(this, _httpRequest2$2, void 0), __privateSet$2(this, _client2$1, client), __privateSet$2(this, _httpRequest2$2, httpRequest);
  }
  list(options) {
    const uri = (options == null ? void 0 : options.includeMembers) === !1 ? "/projects?includeMembers=false" : "/projects";
    return lastValueFrom(_request(__privateGet$2(this, _client2$1), __privateGet$2(this, _httpRequest2$2), {
      uri
    }));
  }
  /**
   * Fetch a project by project ID
   *
   * @param projectId - ID of the project to fetch
   */
  getById(projectId2) {
    return lastValueFrom(_request(__privateGet$2(this, _client2$1), __privateGet$2(this, _httpRequest2$2), {
      uri: `/projects/${projectId2}`
    }));
  }
}
_client2$1 = /* @__PURE__ */new WeakMap(), _httpRequest2$2 = /* @__PURE__ */new WeakMap();
var __accessCheck$1 = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet$1 = (obj, member, getter) => (__accessCheck$1(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd$1 = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet$1 = (obj, member, value, setter) => (__accessCheck$1(obj, member, "write to private field"), member.set(obj, value), value),
  _client,
  _httpRequest$1;
class ObservableUsersClient {
  constructor(client, httpRequest) {
    __privateAdd$1(this, _client, void 0), __privateAdd$1(this, _httpRequest$1, void 0), __privateSet$1(this, _client, client), __privateSet$1(this, _httpRequest$1, httpRequest);
  }
  /**
   * Fetch a user by user ID
   *
   * @param id - User ID of the user to fetch. If `me` is provided, a minimal response including the users role is returned.
   */
  getById(id) {
    return _request(__privateGet$1(this, _client), __privateGet$1(this, _httpRequest$1), {
      uri: `/users/${id}`
    });
  }
}
_client = /* @__PURE__ */new WeakMap(), _httpRequest$1 = /* @__PURE__ */new WeakMap();
var _client2, _httpRequest2$1;
class UsersClient {
  constructor(client, httpRequest) {
    __privateAdd$1(this, _client2, void 0), __privateAdd$1(this, _httpRequest2$1, void 0), __privateSet$1(this, _client2, client), __privateSet$1(this, _httpRequest2$1, httpRequest);
  }
  /**
   * Fetch a user by user ID
   *
   * @param id - User ID of the user to fetch. If `me` is provided, a minimal response including the users role is returned.
   */
  getById(id) {
    return lastValueFrom(_request(__privateGet$1(this, _client2), __privateGet$1(this, _httpRequest2$1), {
      uri: `/users/${id}`
    }));
  }
}
_client2 = /* @__PURE__ */new WeakMap(), _httpRequest2$1 = /* @__PURE__ */new WeakMap();
var __defProp = Object.defineProperty,
  __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value
  }) : obj[key] = value,
  __publicField = (obj, key, value) => (__defNormalProp(obj, typeof key != "symbol" ? key + "" : key, value), value),
  __accessCheck = (obj, member, msg) => {
    if (!member.has(obj)) throw TypeError("Cannot " + msg);
  },
  __privateGet = (obj, member, getter) => (__accessCheck(obj, member, "read from private field"), getter ? getter.call(obj) : member.get(obj)),
  __privateAdd = (obj, member, value) => {
    if (member.has(obj)) throw TypeError("Cannot add the same private member more than once");
    member instanceof WeakSet ? member.add(obj) : member.set(obj, value);
  },
  __privateSet = (obj, member, value, setter) => (__accessCheck(obj, member, "write to private field"), member.set(obj, value), value),
  _clientConfig,
  _httpRequest;
const _ObservableSanityClient = class _ObservableSanityClient2 {
  constructor(httpRequest, config = defaultConfig) {
    __publicField(this, "assets"), __publicField(this, "datasets"), __publicField(this, "live"), __publicField(this, "projects"), __publicField(this, "users"), __privateAdd(this, _clientConfig, void 0), __privateAdd(this, _httpRequest, void 0), __publicField(this, "listen", _listen), this.config(config), __privateSet(this, _httpRequest, httpRequest), this.assets = new ObservableAssetsClient(this, __privateGet(this, _httpRequest)), this.datasets = new ObservableDatasetsClient(this, __privateGet(this, _httpRequest)), this.live = new LiveClient(this), this.projects = new ObservableProjectsClient(this, __privateGet(this, _httpRequest)), this.users = new ObservableUsersClient(this, __privateGet(this, _httpRequest));
  }
  /**
   * Clone the client - returns a new instance
   */
  clone() {
    return new _ObservableSanityClient2(__privateGet(this, _httpRequest), this.config());
  }
  config(newConfig) {
    if (newConfig === void 0) return {
      ...__privateGet(this, _clientConfig)
    };
    if (__privateGet(this, _clientConfig) && __privateGet(this, _clientConfig).allowReconfigure === !1) throw new Error("Existing client instance cannot be reconfigured - use `withConfig(newConfig)` to return a new client");
    return __privateSet(this, _clientConfig, initConfig(newConfig, __privateGet(this, _clientConfig) || {})), this;
  }
  /**
   * Clone the client with a new (partial) configuration.
   *
   * @param newConfig - New client configuration properties, shallowly merged with existing configuration
   */
  withConfig(newConfig) {
    const thisConfig = this.config();
    return new _ObservableSanityClient2(__privateGet(this, _httpRequest), {
      ...thisConfig,
      ...newConfig,
      stega: {
        ...(thisConfig.stega || {}),
        ...(typeof (newConfig == null ? void 0 : newConfig.stega) == "boolean" ? {
          enabled: newConfig.stega
        } : (newConfig == null ? void 0 : newConfig.stega) || {})
      }
    });
  }
  fetch(query, params, options) {
    return _fetch(this, __privateGet(this, _httpRequest), __privateGet(this, _clientConfig).stega, query, params, options);
  }
  /**
   * Fetch a single document with the given ID.
   *
   * @param id - Document ID to fetch
   * @param options - Request options
   */
  getDocument(id, options) {
    return _getDocument(this, __privateGet(this, _httpRequest), id, options);
  }
  /**
   * Fetch multiple documents in one request.
   * Should be used sparingly - performing a query is usually a better option.
   * The order/position of documents is preserved based on the original array of IDs.
   * If any of the documents are missing, they will be replaced by a `null` entry in the returned array
   *
   * @param ids - Document IDs to fetch
   * @param options - Request options
   */
  getDocuments(ids, options) {
    return _getDocuments(this, __privateGet(this, _httpRequest), ids, options);
  }
  create(document, options) {
    return _create(this, __privateGet(this, _httpRequest), document, "create", options);
  }
  createIfNotExists(document, options) {
    return _createIfNotExists(this, __privateGet(this, _httpRequest), document, options);
  }
  createOrReplace(document, options) {
    return _createOrReplace(this, __privateGet(this, _httpRequest), document, options);
  }
  delete(selection, options) {
    return _delete(this, __privateGet(this, _httpRequest), selection, options);
  }
  mutate(operations, options) {
    return _mutate(this, __privateGet(this, _httpRequest), operations, options);
  }
  /**
   * Create a new buildable patch of operations to perform
   *
   * @param selection - Document ID, an array of document IDs, or an object with `query` and optional `params`, defining which document(s) to patch
   * @param operations - Optional object of patch operations to initialize the patch instance with
   * @returns Patch instance - call `.commit()` to perform the operations defined
   */
  patch(selection, operations) {
    return new ObservablePatch(selection, operations, this);
  }
  /**
   * Create a new transaction of mutations
   *
   * @param operations - Optional array of mutation operations to initialize the transaction instance with
   */
  transaction(operations) {
    return new ObservableTransaction(operations, this);
  }
  /**
   * Perform action operations against the configured dataset
   *
   * @param operations - Action operation(s) to execute
   * @param options - Action options
   */
  action(operations, options) {
    return _action(this, __privateGet(this, _httpRequest), operations, options);
  }
  /**
   * Perform an HTTP request against the Sanity API
   *
   * @param options - Request options
   */
  request(options) {
    return _request(this, __privateGet(this, _httpRequest), options);
  }
  /**
   * Get a Sanity API URL for the URI provided
   *
   * @param uri - URI/path to build URL for
   * @param canUseCdn - Whether or not to allow using the API CDN for this route
   */
  getUrl(uri, canUseCdn) {
    return _getUrl(this, uri, canUseCdn);
  }
  /**
   * Get a Sanity API URL for the data operation and path provided
   *
   * @param operation - Data operation (eg `query`, `mutate`, `listen` or similar)
   * @param path - Path to append after the operation
   */
  getDataUrl(operation, path) {
    return _getDataUrl(this, operation, path);
  }
};
_clientConfig = /* @__PURE__ */new WeakMap(), _httpRequest = /* @__PURE__ */new WeakMap();
let ObservableSanityClient = _ObservableSanityClient;
var _clientConfig2, _httpRequest2;
const _SanityClient = class _SanityClient2 {
  constructor(httpRequest, config = defaultConfig) {
    __publicField(this, "assets"), __publicField(this, "datasets"), __publicField(this, "live"), __publicField(this, "projects"), __publicField(this, "users"), __publicField(this, "observable"), __privateAdd(this, _clientConfig2, void 0), __privateAdd(this, _httpRequest2, void 0), __publicField(this, "listen", _listen), this.config(config), __privateSet(this, _httpRequest2, httpRequest), this.assets = new AssetsClient(this, __privateGet(this, _httpRequest2)), this.datasets = new DatasetsClient(this, __privateGet(this, _httpRequest2)), this.live = new LiveClient(this), this.projects = new ProjectsClient(this, __privateGet(this, _httpRequest2)), this.users = new UsersClient(this, __privateGet(this, _httpRequest2)), this.observable = new ObservableSanityClient(httpRequest, config);
  }
  /**
   * Clone the client - returns a new instance
   */
  clone() {
    return new _SanityClient2(__privateGet(this, _httpRequest2), this.config());
  }
  config(newConfig) {
    if (newConfig === void 0) return {
      ...__privateGet(this, _clientConfig2)
    };
    if (__privateGet(this, _clientConfig2) && __privateGet(this, _clientConfig2).allowReconfigure === !1) throw new Error("Existing client instance cannot be reconfigured - use `withConfig(newConfig)` to return a new client");
    return this.observable && this.observable.config(newConfig), __privateSet(this, _clientConfig2, initConfig(newConfig, __privateGet(this, _clientConfig2) || {})), this;
  }
  /**
   * Clone the client with a new (partial) configuration.
   *
   * @param newConfig - New client configuration properties, shallowly merged with existing configuration
   */
  withConfig(newConfig) {
    const thisConfig = this.config();
    return new _SanityClient2(__privateGet(this, _httpRequest2), {
      ...thisConfig,
      ...newConfig,
      stega: {
        ...(thisConfig.stega || {}),
        ...(typeof (newConfig == null ? void 0 : newConfig.stega) == "boolean" ? {
          enabled: newConfig.stega
        } : (newConfig == null ? void 0 : newConfig.stega) || {})
      }
    });
  }
  fetch(query, params, options) {
    return lastValueFrom(_fetch(this, __privateGet(this, _httpRequest2), __privateGet(this, _clientConfig2).stega, query, params, options));
  }
  /**
   * Fetch a single document with the given ID.
   *
   * @param id - Document ID to fetch
   * @param options - Request options
   */
  getDocument(id, options) {
    return lastValueFrom(_getDocument(this, __privateGet(this, _httpRequest2), id, options));
  }
  /**
   * Fetch multiple documents in one request.
   * Should be used sparingly - performing a query is usually a better option.
   * The order/position of documents is preserved based on the original array of IDs.
   * If any of the documents are missing, they will be replaced by a `null` entry in the returned array
   *
   * @param ids - Document IDs to fetch
   * @param options - Request options
   */
  getDocuments(ids, options) {
    return lastValueFrom(_getDocuments(this, __privateGet(this, _httpRequest2), ids, options));
  }
  create(document, options) {
    return lastValueFrom(_create(this, __privateGet(this, _httpRequest2), document, "create", options));
  }
  createIfNotExists(document, options) {
    return lastValueFrom(_createIfNotExists(this, __privateGet(this, _httpRequest2), document, options));
  }
  createOrReplace(document, options) {
    return lastValueFrom(_createOrReplace(this, __privateGet(this, _httpRequest2), document, options));
  }
  delete(selection, options) {
    return lastValueFrom(_delete(this, __privateGet(this, _httpRequest2), selection, options));
  }
  mutate(operations, options) {
    return lastValueFrom(_mutate(this, __privateGet(this, _httpRequest2), operations, options));
  }
  /**
   * Create a new buildable patch of operations to perform
   *
   * @param selection - Document ID, an array of document IDs, or an object with `query` and optional `params`, defining which document(s) to patch
   * @param operations - Optional object of patch operations to initialize the patch instance with
   * @returns Patch instance - call `.commit()` to perform the operations defined
   */
  patch(documentId, operations) {
    return new Patch(documentId, operations, this);
  }
  /**
   * Create a new transaction of mutations
   *
   * @param operations - Optional array of mutation operations to initialize the transaction instance with
   */
  transaction(operations) {
    return new Transaction(operations, this);
  }
  /**
   * Perform action operations against the configured dataset
   * Returns a promise that resolves to the transaction result
   *
   * @param operations - Action operation(s) to execute
   * @param options - Action options
   */
  action(operations, options) {
    return lastValueFrom(_action(this, __privateGet(this, _httpRequest2), operations, options));
  }
  /**
   * Perform a request against the Sanity API
   * NOTE: Only use this for Sanity API endpoints, not for your own APIs!
   *
   * @param options - Request options
   * @returns Promise resolving to the response body
   */
  request(options) {
    return lastValueFrom(_request(this, __privateGet(this, _httpRequest2), options));
  }
  /**
   * Perform an HTTP request a `/data` sub-endpoint
   * NOTE: Considered internal, thus marked as deprecated. Use `request` instead.
   *
   * @deprecated - Use `request()` or your own HTTP library instead
   * @param endpoint - Endpoint to hit (mutate, query etc)
   * @param body - Request body
   * @param options - Request options
   * @internal
   */
  dataRequest(endpoint, body, options) {
    return lastValueFrom(_dataRequest(this, __privateGet(this, _httpRequest2), endpoint, body, options));
  }
  /**
   * Get a Sanity API URL for the URI provided
   *
   * @param uri - URI/path to build URL for
   * @param canUseCdn - Whether or not to allow using the API CDN for this route
   */
  getUrl(uri, canUseCdn) {
    return _getUrl(this, uri, canUseCdn);
  }
  /**
   * Get a Sanity API URL for the data operation and path provided
   *
   * @param operation - Data operation (eg `query`, `mutate`, `listen` or similar)
   * @param path - Path to append after the operation
   */
  getDataUrl(operation, path) {
    return _getDataUrl(this, operation, path);
  }
};
_clientConfig2 = /* @__PURE__ */new WeakMap(), _httpRequest2 = /* @__PURE__ */new WeakMap();
let SanityClient = _SanityClient;
function defineCreateClientExports(envMiddleware2, ClassConstructor) {
  const defaultRequester = defineHttpRequest(envMiddleware2);
  return {
    requester: defaultRequester,
    createClient: config => new ClassConstructor((options, requester2) => (requester2 || defaultRequester)({
      maxRedirects: 0,
      maxRetries: config.maxRetries,
      retryDelay: config.retryDelay,
      ...options
    }), config)
  };
}
function defineDeprecatedCreateClient(createClient2) {
  return function (config) {
    return printNoDefaultExport(), createClient2(config);
  };
}
var envMiddleware = [];
const exp = defineCreateClientExports(envMiddleware, SanityClient),
  requester = exp.requester,
  createClient = exp.createClient,
  deprecatedCreateClient = defineDeprecatedCreateClient(createClient);
export { BasePatch, BaseTransaction, ClientError, ObservablePatch, ObservableSanityClient, ObservableTransaction, Patch, SanityClient, ServerError, Transaction, createClient, deprecatedCreateClient as default, requester, adapter as unstable__adapter, environment as unstable__environment };
